<template>
  <div>

    <v-card-title primary-title class="card-header mx-0 px-4 py-2">
      {{ textoAcaoMassiva() }} Cod. Ação Tática: {{ acao.idAcao }} | Cod. Pagamento: {{ acao.idPagamento }} | {{ acao.tipoAcao }} | Regional: {{ acao.divisao }}
      <popover-lista :objeto="formatarObjeto()" :campos="listaPopover()"/>
      <v-spacer></v-spacer>
      <slot name="depoisTitulo"></slot>
      <geracao-aap-arquivo
        class="ma-3"
        v-if="canAccessGerarAap"
        :houve-selecao="true"
        :pagamentos="pagamentosParaGeracao"
        @GeracaoAaapArquivo_sucesso="recarregar('PENDENTE')">
      </geracao-aap-arquivo>
    </v-card-title>

    <div class="body-1 mx-3 px-2 pb-2">
      <div>{{ acao.descricao }}</div>
      <div class="my-2" v-if="!exibirCampo('periodo_planejamento')">{{ $t('label.periodo_apuracao') }}: {{ acao.dtaInicio }} à {{ acao.dtaFim }}</div>
      <div class="my-2" v-if="exibirCampo('periodo_planejamento')">{{ $t('label.data_inicio') }}: {{ acao.dtaInicio }}</div>
      <div class="my-2" v-if="exibirCampo('periodo_planejamento')">{{ $t('label.data_fim') }}: {{ acao.dtaFim }}</div>
      <div v-if="acao.responsaveis" class="my-2">{{ $tc('label.responsavel', 1) }}: {{ acao.responsaveis }}</div>
      <div v-if="acao.solicitanteAprovacao">{{ acao.solicitanteAprovacao }}</div>
     <div v-if="acao.aprovadores">
        <div v-for="aprovador in acao.aprovadores" :key="aprovador.numSequencia">
          <div> Usuário Aprovador - Passo {{ aprovador.numSequencia }}: {{ aprovador.texto }} </div>
        </div>
      </div>
      <div  class="my-2" v-if="exibeValorPlanejado">
        {{`${$tc('label.valor_acao', 1)}: ${getMoney(acao.valor)}`}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import PopoverLista from '@/produto/shared-components/PopoverLista';
import { getMoney } from '@/produto/common/functions/helpers';
import GeracaoAapArquivo from '@/spa/liquidacao-acao/GeracaoAapArquivo';

export default {
  components: {
    PopoverLista,
    GeracaoAapArquivo,
  },
  props: {
    acao: Object,
    configuracao: Object,
    exibeValorPlanejado: Boolean,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      listPagamentos: [],
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...generateComputed('PGTO', [
      'canEdit',
    ]),
    canEditStatus() {
      return this.canEdit && this.acao.statusPagamento !== 'CANCELADO' && this.acao.statusPagamento !== 'PROGRAMADO';
    },
    canAccessGerarAap() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'GERAR_AAP').length && this.acao.statusPagamento === 'PENDENTE' && !this.acao.suzanoIndAapCriado;
    },
    pagamentosParaGeracao() {
      this.listPagamentos.push({
        ...this.acao,
        id: this.acao.idPagamento,
      });
      return this.listPagamentos;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    getMoney,
    textoAcaoMassiva() {
      return this.acao.idAcaoTradePrevia ? this.$tc('Cod. Planejamento Massivo: ').concat(`${this.acao.idAcaoTradePrevia} | `) : '';
    },
    exibirCampo(campo) {
      if (this.configuracao.passo3) {
        const { listaPadrao } = this.configuracao.passo3;
        return !!listaPadrao.filter((p) => p.fluxos.includes('LIQUIDACAO') && p.label === campo).length;
      }
      return false;
    },
    listaPopover() {
      const lista = [];
      if (this.exibirCampo('status')) {
        lista.push({ campo: 'status' });
      }
      if (this.exibirCampo('unidadenegocio')) {
        lista.push({ campo: 'unidadeNegocio', label: this.$tc('label.unidade_negocio', 1) });
      }
      if (this.exibirCampo('tipo_verba')) {
        lista.push({ campo: 'tipo_verba', label: this.$tc('label.tipo_verba', 1) });
      }

      if (this.configuracao.passo3) {
        const { listaDinamica } = this.configuracao.passo3;
        const camposLiquidacao = listaDinamica.filter((p) => p.fluxos.includes('LIQUIDACAO'));
        camposLiquidacao.forEach((a) => lista.push({
          campo: a.label,
          label: this.montaLabelCampoDinamico(a.label),
        }));
      }
      return lista;
    },
    formatarObjeto() {
      const acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.statusPagamento) {
        acaoFormatada.status = this.$tc(`status_entidade.${this.acao.statusPagamento.toLowerCase()}`, 1);
      }
      return acaoFormatada;
    },
    montaLabelCampoDinamico(campo) {
      const metadado = this.getAcaoMetadado.mapaCamposDinamicos;
      return metadado[campo].nomCampo;
    },
    recarregar(novoStatus) {
      this.$emit('RECARREGAR_PAGAMENTO', novoStatus);
    },
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
